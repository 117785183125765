<template>
  <div class="TimeSlotsAvailability"/>
</template>

<script>
import { SET_ORDER_PROP } from '../../actions'
import TimeSlotsApi from '@/api/TimeSlotsApi'

export default {
  name: 'time-slots-availability',
  props: {
    order: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      timeSlotsAvailabilityInterval: null
    }
  },
  watch: {
    'order.selectedDate'() {
      if (this.order.selectedDate) {
        this.startFetchTimeSlotsAvailability()
      }
    }
  },
  created() {
    if (this.order.selectedDate) {
      this.startFetchTimeSlotsAvailability()
    }
  },
  beforeDestroy() {
    clearInterval(this.timeSlotsAvailabilityInterval)
  },
  methods: {
    startFetchTimeSlotsAvailability() {
      this.onChangeTimeSlotsAvailability(null)
      clearInterval(this.timeSlotsAvailabilityInterval)
      this.timeSlotsAvailabilityInterval = setInterval(this.fetchTimeSlotsAvailability, 1000 * 60)
      this.fetchTimeSlotsAvailability()
    },
    async fetchTimeSlotsAvailability() {
      if (this.order.selectedDate) {
        let eventsLimit
        let buffetLimit
        let miniBuffetLimit
        const result = (await TimeSlotsApi.index(this.order.selectedDate, eventsLimit, buffetLimit, miniBuffetLimit))
        if (result && result.timeSlotsAvailability && this.order.selectedDate && this.order.selectedDate.indexOf(result.date) === 0) {
          this.onChangeTimeSlotsAvailability(result.timeSlotsAvailability)
        }
      }
    },
    onChangeTimeSlotsAvailability(timeSlotsAvailability) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.index,
        prop: 'timeSlotsAvailability',
        value: timeSlotsAvailability
      })
    }
  }
}
</script>
