<template>
  <div
    class="ProgressElement"
    :class="{'Pointer': clickable}"
    @click="gotoLink">
    <div
      class="ProgressBar"
      :class="{'Completed': completed}"/>
    <div
      class="ProgressLabel"
      :class="{'Active': active}">
      {{ index + 1 }} - {{ element.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'progress-element',
  props: {
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    completed: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    clickable() {
      return this.element.link && this.completed && !this.active
    }
  },
  methods: {
    gotoLink() {
      if (this.clickable) {
        this.$router.push(this.element.link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.ProgressElement {
  display: inline-block;
  vertical-align: top;
  min-width: 160px;

  @media #{$tablet-down} {
    width: 33.333333%;
    min-width: auto;
  }
  &.Pointer {
    cursor: pointer;

    .ProgressLabel:hover {
      color: $ink;
      font-weight: bold;
    }
  }
  .ProgressBar {
    height: 4px;
    background-color: $primary-bg-light;

    &.Completed {
      background-color: $primary;
    }
  }
  .ProgressLabel {
    color: $gray-dark;
    @extend %body;
    padding-top: $space-xs;
    padding-right: $space-xs;
    white-space: normal;
    text-align: left;

    &.Active {
      color: $ink;
      font-weight: bold;
    }
  }
}
</style>
