<template>
  <div class="OrderSubtotal">
    <div
      v-if="additionalChargeItems.length > 0 || formattedFestiveSurcharge || formattedMinimumPaxSurcharge"
      class="AdditionalChargesSection">
      <div
        v-if="!checkout && !floating"
        class="AdditionalChargesSectionTitle">
        {{ $t('order.additionalChargesTitle') }}
      </div>

      <tabulation-item
        v-for="(selectedMenuSectionMeal, index) in additionalChargeItems"
        :key="`additional-charge-${index}`"
        :leftColumnWidth="leftColumnWidth"
        class="AdditionalCharge">
        <span>
          {{ getMealName(selectedMenuSectionMeal) }}
        </span>
        <span slot="right">
          {{ $t('order.additionalChargePerPax', { price: formatCost(selectedMenuSectionMeal.additionalChargePerPax) }) }}
        </span>
      </tabulation-item>

      <tabulation-item
        v-if="formattedFestiveSurcharge"
        :leftColumnWidth="leftColumnWidth"
        class="Surcharge">
        <span>
          {{ $t('order.festiveSurchargeLabel') }}
        </span>
        <span
          slot="right"
          class="Price">
          {{ $t('order.additionalChargePerPax', { price: formattedFestiveSurcharge }) }}
        </span>
      </tabulation-item>

      <tabulation-item
        v-if="formattedMinimumPaxSurcharge"
        :leftColumnWidth="leftColumnWidth"
        class="Surcharge">
        <span>
          {{ $t('order.minimumPaxSurchargeLabel') }}
        </span>
        <span
          slot="right"
          class="Price">
          {{ $t('order.additionalChargePerPax', { price: formattedMinimumPaxSurcharge }) }}
        </span>
      </tabulation-item>
    </div>

    <div class="SubtotalSection">
      <tabulation-item
        :leftColumnWidth="leftColumnWidth"
        class="SubtotalItem">
        <span>
          {{ $t('order.subtotalLabel') }}
        </span>
        <template slot="right">
          <span class="Price">
            {{ $t('common.price', { price: formatCostWithDecimal(subtotal) }) }}
          </span>
        </template>
      </tabulation-item>

      <div
        v-if="!checkout && order.selectedAddOns && order.selectedAddOns.length > 0"
        class="SelectedAddOnsSection">
        <selected-add-ons/>
      </div>
      <tabulation-item
        v-else
        :leftColumnWidth="leftColumnWidth">
        <span>
          {{ $t('order.addOnsLabel') }}
        </span>
        <template slot="right">
          <span class="Price">
            {{ $t('common.price', { price: formatCostWithDecimal(selectedAddOnsCost) }) }}
          </span>
        </template>
      </tabulation-item>

      <tabulation-item
        v-if="showDeliveryFee"
        :leftColumnWidth="leftColumnWidth">
        <span v-if="hasSpecialLocationDeliveryCharge">
          {{ $t('order.specialLocationDeliveryChargeLabel') }}
        </span>
        <span v-else>
          {{ $t('order.deliveryFeeLabel') }}
        </span>
        <template slot="right">
          <span class="Price">
            {{ deliveryFee === 0 ? $t('common.deliveryFeeWaivedLabel') : $t('common.price', { price: formatCostWithDecimal(deliveryFee) }) }}
          </span>
        </template>
      </tabulation-item>

      <slot name="afterDeliveryFee"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getAdditionalChargeItems,
  getDeliveryFee,
  getMinimumPaxSurcharge,
  getSelectedAddOnsCost,
  getSpecialLocationDeliveryCharge,
  getSubtotal,
  getTotalAdditionalChargesPerPax
} from '@/assets/js/order-helper'
import SelectedAddOns from './SelectedAddOns'
import TabulationItem from '@/components/common/TabulationItem'
import { formatCost } from '@/assets/js/common'
import { getFestiveSurcharge } from '@/assets/js/highlight-menu-helper'

export default {
  name: 'order-subtotal',
  components: {
    SelectedAddOns,
    TabulationItem
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    leftColumnWidth: {
      type: Number,
      default: 60
    },
    checkout: {
      type: Boolean,
      default: false
    },
    showDeliveryFee: {
      type: Boolean,
      default: true
    },
    floating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getCustomer'
    ]),
    minimumPaxSurcharge() {
      return getMinimumPaxSurcharge(this.order)
    },
    formattedMinimumPaxSurcharge() {
      return this.minimumPaxSurcharge ? formatCost(this.minimumPaxSurcharge) : null
    },
    additionalChargeItems() {
      return getAdditionalChargeItems(this.order)
    },
    totalAdditionalChargesPerPax() {
      return getTotalAdditionalChargesPerPax(
        this.order,
        this.additionalChargeItems
      )
    },
    selectedAddOnsCost() {
      return getSelectedAddOnsCost(this.order)
    },
    subtotal() {
      return getSubtotal(this.order, this.minimumPaxSurcharge, this.totalAdditionalChargesPerPax)
    },
    hasSpecialLocationDeliveryCharge() {
      const specialDeliveryCharge = getSpecialLocationDeliveryCharge(this.order, this.checkout ? this.getCustomer : null)
      return specialDeliveryCharge > 0 && specialDeliveryCharge === this.deliveryFee
    },
    deliveryFee() {
      return getDeliveryFee(this.order, null, null, this.checkout ? this.getCustomer : null)
    },
    festiveSurcharge() {
      return getFestiveSurcharge(this.order)
    },
    formattedFestiveSurcharge() {
      return this.festiveSurcharge ? formatCost(this.festiveSurcharge) : null
    }
  },
  methods: {
    formatCost(cost) {
      return formatCost(cost)
    },
    formatCostWithDecimal(cost) {
      return parseFloat(cost).toFixed(2)
    },
    getMealName(menuSectionMeal) {
      return menuSectionMeal.meal.name.replace(/\s\(V\)/g, '').replace(/\s\(Vg\)/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.OrderSubtotal {
  .SelectedAddOnsSection,
  .AdditionalChargesSection {
    margin-bottom: $space-m;
  }
  .AdditionalChargesSection {
    .AdditionalChargesSectionTitle {
      @extend %subheading;
      margin-bottom: $space-m;
    }
  }
  /deep/ .TabulationItem {
    @extend %body;
    &:not(:last-child) {
      margin-bottom: $space-m;
    }
    &.SubtotalItem {
      @extend %heading;
    }
  }
}
</style>
