import HTTPService from '@/api/base/HTTPService'

const PAPERCUT_API_VERSION_ROOT = '/api/v1'
export const PAPERCUT_API_URL = `${process.env.VUE_APP_PAPERCUT_URL}${PAPERCUT_API_VERSION_ROOT}`

const PLATFORM = 'catering'

const PapercutApi = HTTPService()
PapercutApi.setup({
  getPlatform() {
    return PLATFORM
  },
  getAuthorizationKey: () => {
    return process.env.VUE_APP_PAPERCUT_API_KEY
  }
})
export default PapercutApi
