<template>
  <div class="ProgressTracker">
    <progress-element
      v-for="(element, index) in elements"
      :key="`progress_step_${index}`"
      :element="element"
      :index="index"
      :completed="index <= currentStep - 1"
      :active="index === currentStep - 1"/>
  </div>
</template>

<script>
import ProgressElement from './ProgressElement'

export default {
  name: 'progress-tracker',
  components: {
    ProgressElement
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      elements: [
        {
          label: this.$t('menu.progressTracker.selectMenuLabel'),
          link: '/catering'
        },
        {
          label: this.$t('menu.progressTracker.selectItemsLabel'),
          link: '/catering/select-menu-items'
        },
        {
          label: this.$t('menu.progressTracker.checkoutLabel'),
          link: '/catering/checkout'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.ProgressTracker {
  white-space: nowrap;
}
</style>
