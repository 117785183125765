import PapercutApi, { PAPERCUT_API_URL } from '@/api/base/PapercutApi'

export default {
  index(date, eventsLimit = null, buffetLimit = null, miniBuffetLimit = null) {
    const data = {
      events_limit: eventsLimit,
      buffet_limit: buffetLimit,
      mini_buffet_limit: miniBuffetLimit
    }
    return PapercutApi.get(`${PAPERCUT_API_URL}/time_slots/${date}`, { params: data })
  },
  closedHours() {
    return PapercutApi.get(`${PAPERCUT_API_URL}/closed_hours`)
  }
}
