export const SET_DISABLED_DATES = 'setDisabledDates'
export const SET_SELECTED_ABOUT = 'setSelectedAbout'
export const SET_SELECTED_DATE = 'setSelectedDate'
export const SET_SELECTED_PAX = 'setSelectedPax'

export const ADD_ORDER = 'addOrder'
export const CLEAR_CART = 'clearCart'
export const DELETE_ORDER = 'deleteOrder'
export const FETCH_CART = 'fetchCart'
export const FETCH_FULFILMENT_OPTION = 'fetchFulfilmentOption'
export const FETCH_PAYMENT_BREAKDOWN = 'fetchPaymentBreakdown'
export const FETCH_UPDATED_CART = 'fetchUpdatedCart'
export const FETCH_UPDATED_SERVICE_DATA = 'fetchUpdatedServiceData'
export const RESET_FULFILMENT_STATE = 'resetFulfilmentState'
export const RESET_ORDER_STATE = 'resetOrderState'
export const SET_ACTIVE_ORDER_ID = 'setActiveOrderId'
export const SET_CHECKOUT_COMPLETE = 'setCheckoutComplete'
export const SET_CHECKOUT_ERRORS_VISIBLE = 'setCheckoutErrorsVisible'
export const SET_ORDER_PAID = 'setOrderPaid'
export const SET_ORDER_PROP = 'setOrderProp'
export const SET_READY_FOR_CHECKOUT = 'setReadyForCheckout'
export const SET_SALES_ORDER = 'setSalesOrder'

export const RESET_MENU_STATE = 'resetMenuState'
export const SET_ADD_ON_MENUS = 'setAddOnMenus'
export const SET_HIGHLIGHT_MENUS = 'setHighlightMenus'
export const SET_MENU_COVER = 'setMenuCover'
export const SET_MENU_ID = 'setMenuId'
export const SET_SELECTED_MENU = 'setSelectedMenu'
export const SET_SEMINAR_MENUS = 'setSeminarMenus'
export const UPDATE_ORDER_MENU_SECTION_MEALS = 'updateOrderMenuSectionMeals'
export const SET_IFRAME_MENU = 'setIframeMenu'

export const SET_BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS = 'setBillingAddressSameAsDeliveryAddress'
export const SET_CREATE_ACCOUNT = 'setCreateAccount'
export const SET_CUSTOMER = 'setCustomer'
export const SET_IS_CORPORATE_ORDER = 'setIsCorporateOrder'
export const SET_IS_DISPOSABLES_REQUIRED = 'setIsDisposablesRequired'
export const SET_PROMO_CODE = 'setPromoCode'
export const SET_SECONDARY_CONTACT_NAME = 'setSecondaryContactName'
export const SET_SECONDARY_CONTACT_NUMBER = 'setSecondaryContactNumber'
export const SET_SELECTED_BILLING_ADDRESS = 'setSelectedBillingAddress'
export const SET_SELECTED_DELIVERY_ADDRESS = 'setSelectedDeliveryAddress'
export const SET_SPECIAL_INSTRUCTIONS = 'setSpecialInstructions'
export const SET_VENUE_OPEN_AT_SETUP_TIME = 'setVenueOpenAtSetupTime'

export const FETCH_SESSION_USER_ID = 'user/fetchSessionUserId'
export const SET_USER_DATA = 'user/setUserData'

export const SET_LOCATION_CONFIGS = 'setLocationConfigs'
export const SET_SPECIAL_LOCATION_DELIVERY_CHARGE = 'setSpecialLocationDeliveryCharge'
