<template>
  <grid-row
    class="TwoColumns"
    :keepColumnWidth="keepColumnWidth">
    <grid-column
      :valign="valign"
      :align="leftColumnAlign || align"
      :width="leftColumnWidth"
      :class="{'Empty': !$slots.default}">
      <slot/>
    </grid-column>
    <grid-column
      v-if="leftColumnWidth !== 100"
      :align="rightColumnAlign || align"
      :valign="valign"
      :width="100 - leftColumnWidth"
      :class="{'Empty': !$slots.right}">
      <slot name="right"/>
    </grid-column>
  </grid-row>
</template>

<script>
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'

export default {
  name: 'two-columns',
  components: {
    GridRow,
    GridColumn
  },
  props: {
    leftColumnWidth: {
      type: Number,
      default: 50
    },
    valign: {
      type: String,
      default: 'top'
    },
    align: {
      type: String,
      default: null
    },
    leftColumnAlign: {
      type: String,
      default: null
    },
    rightColumnAlign: {
      type: String,
      default: null
    },
    keepColumnWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>
