<template>
  <two-columns
    class="TabulationItem"
    :leftColumnWidth="leftColumnWidth"
    rightColumnAlign="right"
    :keepColumnWidth="true">
    <slot/>
    <template slot="right">
      <slot name="right"/>
    </template>
  </two-columns>
</template>

<script>
import TwoColumns from '@/components/common/TwoColumns'

export default {
  name: 'tabulation-item',
  components: {
    TwoColumns
  },
  props: {
    leftColumnWidth: {
      type: Number,
      default: null
    }
  }
}
</script>
