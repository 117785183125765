<template>
  <div class="SelectedAddOns">
    <div class="SelectedAddOnsTitle">
      {{ $t('addOns.title') }}
    </div>
    <grid-row
      v-for="(selectedAddOn, index) in selectedAddOns"
      :key="`add-on-${index}`"
      class="SelectedAddOn"
      :keepColumnWidth="true">
      <grid-column
        :width="(100 - leftColumnWidth) * 0.3">
        {{ selectedAddOn.quantity }}x
      </grid-column>
      <grid-column :width="leftColumnWidth">
        {{ selectedAddOn.item.name }}
      </grid-column>
      <grid-column
        align="right"
        :width="(100 - leftColumnWidth) * 0.7">
        <span class="Price">
          {{ $t('common.price', { price: formatCostWithDecimal(lineItemPrice(selectedAddOn)) }) }}
        </span>
      </grid-column>
    </grid-row>
    <grid-row
      v-if="totalFestiveSurcharge > 0"
      class="SelectedAddOn"
      :keepColumnWidth="true">
      <grid-column
        :width="(100 - leftColumnWidth) * 0.3">
        {{ totalAddOnQuantity }}x
      </grid-column>
      <grid-column :width="leftColumnWidth">
        {{ $t('order.festiveSurchargeLabel') }}
      </grid-column>
      <grid-column
        align="right"
        :width="(100 - leftColumnWidth) * 0.7">
        <span class="Price">
          {{ $t('common.price', { price: formatCostWithDecimal(totalFestiveSurcharge) }) }}
        </span>
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFestiveSurcharge, isBentoMenu } from '@/assets/js/highlight-menu-helper'
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'

export default {
  name: 'selected-add-ons',
  components: {
    GridRow,
    GridColumn
  },
  props: {
    leftColumnWidth: {
      type: Number,
      default: 60
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder'
    ]),
    selectedAddOns() {
      if (this.getActiveOrder) {
        return this.getActiveOrder.selectedAddOns
      }
      return []
    },
    isBentoMenu() {
      if (this.getActiveOrder) {
        return isBentoMenu(this.getActiveOrder.menuDetails.id)
      }
      return false
    },
    festiveSurcharge() {
      if (this.getActiveOrder) {
        return getFestiveSurcharge(this.getActiveOrder)
      }
      return 0
    },
    totalAddOnQuantity() {
      return this.selectedAddOns.reduce((total, selectedAddOn) => total + selectedAddOn.quantity, 0)
    },
    totalFestiveSurcharge() {
      if (this.isBentoMenu) {
        return this.festiveSurcharge * this.totalAddOnQuantity
      }
      return 0
    }
  },
  methods: {
    formatCostWithDecimal(cost) {
      return parseFloat(cost).toFixed(2)
    },
    lineItemPrice(selectedAddOn) {
      return selectedAddOn.price * selectedAddOn.quantity + parseFloat(selectedAddOn.additionalFee || 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.SelectedAddOns {
  .SelectedAddOnsTitle {
    @extend %subheading;
    margin-bottom: $space-xs;
  }
  .SelectedAddOn {
    @extend %body;
    margin-bottom: $space-xs;
  }
}
</style>
