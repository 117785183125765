<template>
  <div class="OrdersTotal">
    <tabulation-item :leftColumnWidth="leftColumnWidth">
      <span>
        {{ $t('order.totalLabel') }}
      </span>
      <span slot="right">
        {{ $t('common.price', { price: total }) }}
      </span>
    </tabulation-item>
    <tabulation-item :leftColumnWidth="leftColumnWidth">
      <span>
        {{ $t('order.totalWithGstLabel') }}
      </span>
      <span slot="right">
        {{ $t('common.price', { price: totalWithGst }) }}
      </span>
    </tabulation-item>
  </div>
</template>

<script>
import TabulationItem from '@/components/common/TabulationItem'

export default {
  name: 'orders-total',
  components: {
    TabulationItem
  },
  props: {
    leftColumnWidth: {
      type: Number,
      default: 50
    },
    checkout: {
      type: Boolean,
      default: false
    },
    total: {
      type: String,
      required: true
    },
    totalWithGst: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.OrdersTotal {
  font-weight: bold;
  color: $ink;

  .TabulationItem:not(:last-child) {
    margin-bottom: $space-m;
  }
}
</style>
